import React, { FC, Fragment } from 'react';
import classnames from 'classnames';
import { graphql } from 'gatsby';
import Container from 'react-bootstrap/Container';

import GatsbyImage from 'components/common/GatsbyImage';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';

import useScreenRecognition from 'hooks/useScreenRecognition';
import { TopBannerProps } from './model';
import './TopBanner.scss';

const TopBanner: FC<TopBannerProps> = ({
  title,
  description,
  isDescriptionFirst,
  image,
  imageAlt,
  mobileImage,
  mobileImageAlt,
  classes,
  imageStrategy,
  imageStyles,
  bannerBackground,
  isContentWrapped,
  bannerTheme,
}) => {
  const { isMobile, isNotMobile } = useScreenRecognition();
  const cx = classnames('gs-top-banner', {
    [`${classes}`]: classes,
    [`${bannerTheme}`]: bannerTheme,
    [`gs-top-banner--background-${bannerBackground?.label}`]: bannerBackground?.label,
  });

  const ContentWrapper = isContentWrapped === '1' ? Container : Fragment;

  return (
    <div className={cx}>
      <ContentWrapper fluid className="gs-top-banner__wrapper">
        <div className="gs-top-banner__text">
          {description && isDescriptionFirst ? (
            <DangerouslySetInnerHtml
              className="gs-top-banner__text-description"
              html={description}
            />
          ) : null}
          <h1 className="gs-top-banner__text-title gs-hero-title">
            {title.map(({ value }) => (
              <DangerouslySetInnerHtml
                key={value}
                element="span"
                className="gs-top-banner__text-line"
                html={value}
              />
            ))}
          </h1>
          {description && !isDescriptionFirst ? (
            <DangerouslySetInnerHtml
              className="gs-top-banner__text-description"
              html={description}
            />
          ) : null}
        </div>
        {isMobile ? (
          <div className="gs-top-banner__image-mobile">
            {mobileImage?.fallbackUrl ? (
              <GatsbyImage
                className="gs-top-banner__image-img"
                fluid={mobileImage}
                useFocalPoint
                alt={mobileImageAlt}
                isLazyLoading={false}
                fadeIn={false}
              />
            ) : null}
          </div>
        ) : null}
        {isNotMobile ? (
          <div className="gs-top-banner__image-desktop">
            {image?.fallbackUrl ? (
              <GatsbyImage
                className="gs-top-banner__image-img"
                isLazyLoading={false}
                useFocalPoint
                fluid={image}
                imgStyle={imageStyles}
                objectFit={imageStrategy}
                alt={imageAlt}
              />
            ) : null}
          </div>
        ) : null}
      </ContentWrapper>
    </div>
  );
};

export const query = graphql`
  fragment FragmentHomePageTopBanner on THomeBannerStructure {
    properties {
      description
      veevaNumber
      isDescriptionFirst
      homepageBannerDisclaimer
      image {
        ...FragmentGatsbyImage
      }
      imageAlt
      bgImage {
        ...FragmentGatsbyImage
      }
      bgImageMobile {
        ...FragmentGatsbyImage
      }
      productImage {
        ...FragmentGatsbyProps
        gatsbyImage {
          ...FragmentSmallImage
        }
        mobileImage {
          childImageSharp {
            fluid(maxHeight: 140, quality: 80) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
      productImageAlt
      productSlogan
      ribbonImage {
        ...FragmentGatsbyImage
      }
      ribbonImageAlt
      showRibbonOnMobile
      title {
        value
      }
      link {
        url
        name
        queryString
      }
      linkVariant
      ariaScroll
      scrollAnchor
      bannerType
      bannerTheme
    }
    structure
  }
  fragment FragmentTopBanner on TTopBannerStructure {
    properties {
      image {
        ...FragmentGatsbyImage
      }
      mobileImage {
        ...FragmentGatsbyProps
        gatsbyImage {
          ...FragmentSmallImage
        }
        mobileImage {
          childImageSharp {
            fluid(maxWidth: 768, quality: 80) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
      description
      isDescriptionFirst
      mobileImageAlt
      title {
        value
      }
      imageAlt
      bannerBackground {
        label
      }
      bannerTheme
    }
  }
  fragment FragmentBottomBanner on TInfobannerStructure {
    properties {
      description
      image {
        ...FragmentGatsbyImage
      }
      imageAlt
    }
  }
`;

export default TopBanner;
