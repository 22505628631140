import React, { FC, useState, useEffect, useCallback, useRef } from 'react';
import Container from 'react-bootstrap/Container';

import ProductCard from 'components/ProductCard';
import Button from 'components/common/Button';
import IconCustom from 'components/common/IconCustom';
import QuizLink from 'components/QuizLink';

import useScreenRecognition from 'hooks/useScreenRecognition';
import { parseBoolean } from 'utils/parseHelpers';
import { sortRelatedProducts } from 'utils/helpers';
import { gtmService } from 'services/gtmService';

import { IProductCardsListProps } from './model';

import './ProductCardsList.scss';

const ProductCardsList: FC<IProductCardsListProps> = ({
  relatedProducts,
  limit,
  limitMobile,
  more,
  ariaMore,
  linkCompare,
  showCompareLink,
  linkQuiz,
  productsLinks,
  isFamily,
  cardTheme,
  listingName,
}) => {
  const { isNotMobile } = useScreenRecognition();
  const [toShow, setToShow] = useState<number>(0);
  const startCursor = useRef(0);
  const endCursor = useRef(0);

  const factor = linkQuiz?.length ? 1 : 0;
  const stepLoad = isNotMobile ? limit - factor : limitMobile - factor;
  const loadMore = useCallback(() => setToShow(toShow + stepLoad), [toShow]);

  useEffect(() => {
    setToShow(stepLoad);
  }, [stepLoad]);

  const relatedProductsArr = relatedProducts?.nodes?.length
    ? sortRelatedProducts(productsLinks, relatedProducts?.nodes)
    : [];

  useEffect(() => {
    endCursor.current = Math.min(relatedProductsArr.length, toShow);

    let eventTimeoutId;
    const timeoutId = setTimeout(() => {
      if (startCursor.current !== endCursor.current) {
        const itemsToSend = relatedProductsArr.slice(startCursor.current, endCursor.current);
        startCursor.current = endCursor.current;
        eventTimeoutId = gtmService.emitProductListingView(listingName, itemsToSend);
      }
    }, 1000);

    return () => {
      clearTimeout(timeoutId);
      clearTimeout(eventTimeoutId);
    };
  }, [toShow]);

  return relatedProductsArr.length ? (
    <div className="product-list__section">
      <Container fluid>
        {parseBoolean(showCompareLink) && linkCompare?.length ? (
          <div className="product-list__compare">
            <Button variant="link" to={linkCompare[0].url}>
              {linkCompare[0].name}
              <IconCustom icon="chevron-right" />
            </Button>
          </div>
        ) : null}
        <div className="product-list" data-testid="product-list">
          <>
            {relatedProductsArr.slice(0, toShow).map((product, index) => {
              const id = `${product.cardTitle}_${index}`;
              return <ProductCard key={id} {...{ ...product, isFamily, cardTheme }} />;
            })}
            {linkQuiz?.length ? <QuizLink {...{ linkQuiz }} /> : null}
          </>
        </div>
        {relatedProductsArr.length > 0 && more && toShow < relatedProductsArr.length ? (
          <div className="product-list__btn-wrap">
            <Button
              ariaLabel={ariaMore}
              clickHandler={loadMore}
              classes="product-list__btn"
              variant="secondary"
            >
              {more}
              <IconCustom icon="chevron-right" />
            </Button>
          </div>
        ) : null}
      </Container>
    </div>
  ) : null;
};
export default ProductCardsList;
